// See MediaDeviceKind types
export const MEDIA_DEVICE_KIND = {
  VIDEO_INPUT: "videoinput",
  AUDIO_INPUT: "audioinput",
  AUDIO_OUTPUT: "audiooutput",
};

export const MEDIA_DEVICE_DEFAULT_ID = "default";

// Find more info here:
// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/Video_codecs#choosing_a_video_codec
// https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter
export const CODEC = {
  WEBM: "video/webm",
  WEBM8: "video/webm; codecs=vp8",
  WEBM9: "video/webm; codecs=vp9",
  AVCH264: "video/mp4; codecs=H264",
  AVC1: 'video/mp4; codecs="avc1.4d002a"',
  AV1: 'video/mp4; codecs="av01.1.15M.10"',
};

export const CODEC_FILE_EXTENSION = {
  [CODEC.WEBM]: "webm",
  [CODEC.WEBM8]: "webm",
  [CODEC.WEBM9]: "webm",
  [CODEC.AVCH264]: "mp4",
  [CODEC.AVC1]: "mp4",
  [CODEC.AV1]: "mp4",
};
