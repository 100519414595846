import { MEDIA_DEVICE_DEFAULT_ID, MEDIA_DEVICE_KIND } from "@whyuz/data";
import { Buffer } from "buffer";
import { Decoder, Reader, tools } from "ts-ebml";

// This is needed to use ts-ebml
window.Buffer = Buffer;

export const convertVideoBlobToSeekable = async (videoBlob: Blob): Promise<Blob> => {
  const decoder = new Decoder();
  const reader = new Reader();

  const buffer = await videoBlob.arrayBuffer();
  const elms = decoder.decode(buffer);

  for (const elm of elms) {
    reader.read(elm);
  }
  reader.stop();

  const refinedMetadataBuf = tools.makeMetadataSeekable(reader.metadatas, reader.duration, reader.cues);
  const body = buffer.slice(reader.metadataSize);

  return new Blob([refinedMetadataBuf, body], {
    type: videoBlob.type,
  });
};

export const getAvailableMicrophoneDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const microphoneDevices: MediaDeviceInfo[] = [];
  devices.forEach((device) => {
    if (device.kind === MEDIA_DEVICE_KIND.AUDIO_INPUT) {
      microphoneDevices.push(device);
    }
  });

  return microphoneDevices;
};

export const getAvailableSpeakerDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const speakerDevices: MediaDeviceInfo[] = [];
  devices.forEach((device) => {
    if (device.kind === MEDIA_DEVICE_KIND.AUDIO_OUTPUT) {
      speakerDevices.push(device);
    }
  });

  return speakerDevices;
};

export const getAvailableCameraDevices = async () => {
  await navigator.mediaDevices.getUserMedia({ audio: true, video: true }); // This is just to request access to the devices
  const devices = await navigator.mediaDevices.enumerateDevices();
  const cameraDevices: MediaDeviceInfo[] = [];
  devices.forEach((device) => {
    if (device.kind === MEDIA_DEVICE_KIND.VIDEO_INPUT) {
      cameraDevices.push(device);
    }
  });
  return cameraDevices;
};

export const getCameraDevice = async (deviceId?: string) => {
  const deviceIdToSearch = deviceId ?? MEDIA_DEVICE_DEFAULT_ID;
  const cameraDevices = getAvailableCameraDevices();
  (await cameraDevices).forEach((device: MediaDeviceInfo) => {
    if (device.deviceId === deviceIdToSearch) {
      return device;
    }
    return;
  });

  // In case a default device is not found, the first is returned
  let deviceToReturn: MediaDeviceInfo | null = null;
  if (deviceIdToSearch === MEDIA_DEVICE_DEFAULT_ID) {
    deviceToReturn = (await cameraDevices)[0] ?? null;
  }

  return deviceToReturn;
};

export const getMicrophoneDevice = async (deviceId?: string) => {
  const deviceIdToSearch = deviceId ?? MEDIA_DEVICE_DEFAULT_ID;
  const microphoneDevices = getAvailableMicrophoneDevices();
  (await microphoneDevices).forEach((device) => {
    if (device.deviceId === deviceIdToSearch) {
      return device;
    }
    return;
  });

  // In case a default device is not found, the first is returned
  let deviceToReturn: MediaDeviceInfo | null = null;
  if (deviceIdToSearch === MEDIA_DEVICE_DEFAULT_ID) {
    deviceToReturn = (await microphoneDevices)[0] ?? null;
  }
  return deviceToReturn;
};

export const getSpeakersDevice = async (deviceId?: string) => {
  let deviceIdToSearch = deviceId;
  if (deviceIdToSearch === null) {
    deviceIdToSearch = MEDIA_DEVICE_DEFAULT_ID;
  }

  const speakerDevices = getAvailableSpeakerDevices();
  (await speakerDevices).forEach((device) => {
    if (device.deviceId === deviceIdToSearch) {
      return device;
    }
    return;
  });

  // In case a default device is not found, the first is returned
  let deviceToReturn: MediaDeviceInfo | null = null;
  if (deviceIdToSearch === MEDIA_DEVICE_DEFAULT_ID) {
    deviceToReturn = (await speakerDevices)[0] ?? null;
  }
  return deviceToReturn;
};

export const captureSnapshotFromVideo = (video: HTMLVideoElement, width?: number, height?: number) => {
  const canvas = captureCanvasFromVideo(video, width, height);
  return canvas.toDataURL();
};

export const captureCanvasFromVideo = (video: HTMLVideoElement, width?: number, height?: number) => {
  const canvas = document.createElement("canvas");
  canvas.width = width ?? video.videoWidth;
  canvas.height = height ?? video.videoHeight;
  canvas.getContext("2d")?.drawImage(video, 0, 0, canvas.width, canvas.height);
  return canvas;
};

export const getVideoDuration = (src: string) => {
  const video = document.createElement("video");
  return new Promise((resolve: (duration: number) => void, reject: (error: unknown) => void) => {
    if (video.duration > 0 && video.duration !== Infinity) {
      if (process.env.NODE_ENV === "development" || process.env.NODE_ENV === "test") {
        console.log("Video duration:", video.duration);
      }
      resolve(video.duration);
      return;
    }

    const handleOnLoad = () => {
      try {
        resolve(video.duration);
      } catch (e) {
        reject(e);
      } finally {
        video.removeEventListener("loadeddata", handleOnLoad);
      }
    };
    video.preload = "auto";
    video.addEventListener("loadeddata", handleOnLoad);
    video.src = src;
  });
};

/**
 * Extracts frames from the video and returns them as an array of imageData
 * @param videoUrl url to the video file (html5 compatible format) eg: mp4
 * @param framesToExtract total number of frames that you want to extract
 */
export const getVideoFrames = (videoUrl: string, framesToExtract: number): Promise<ImageData[]> => {
  const getVideoFrame = (
    video: HTMLVideoElement,
    context: CanvasRenderingContext2D,
    time: number,
  ): Promise<ImageData> => {
    return new Promise((resolve: (frame: ImageData) => void, reject: (error: unknown) => void) => {
      try {
        const eventCallback = () => {
          try {
            video.removeEventListener("seeked", eventCallback);
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            const imageData = context.getImageData(0, 0, video.videoWidth, video.videoHeight);
            resolve(imageData);
          } catch (e) {
            reject(e);
          }
        };
        video.addEventListener("seeked", eventCallback);
        video.currentTime = time;
      } catch (e) {
        reject(e);
      }
    });
  };

  return new Promise((resolve: (frames: ImageData[]) => void, reject: (error: unknown) => void) => {
    try {
      const canvas: HTMLCanvasElement = document.createElement("canvas");
      const context = canvas.getContext("2d", { willReadFrequently: true });
      if (!context) return;

      const video = document.createElement("video");
      video.crossOrigin = "Anonymous";
      video.preload = "auto";
      video.addEventListener("loadeddata", () => {
        const processFrames = async () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const frames: ImageData[] = [];
          for (let time = 0; time < video.duration; time += video.duration / framesToExtract) {
            const frame = await getVideoFrame(video, context, time);
            frames.push(frame);
          }
          return frames;
        };
        processFrames()
          .then((frames) => resolve(frames))
          .catch((e) => reject(e));
      });
      video.src = videoUrl;
      video.load();
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * Extracts frames from the video and returns them as an array of imageData
 * @param videoUrl url to the video file (html5 compatible format) eg: mp4
 * @param framesToExtract total number of frames that you want to extract
 */
export const getVideoFramesParallel = (videoUrl: string, framesToExtract: number): Promise<ImageData[]> => {
  const getVideoFrame = (videoUrl: string, time: number): Promise<ImageData> => {
    return new Promise((resolve: (frame: ImageData) => void, reject: (error: unknown) => void) => {
      try {
        const canvas: HTMLCanvasElement = document.createElement("canvas");
        const video = document.createElement("video");

        const handleVideoSeeked = () => {
          try {
            video.removeEventListener("seeked", handleVideoSeeked);
            const context = canvas.getContext("2d");
            if (context) {
              context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
              const imageData = context.getImageData(0, 0, video.videoWidth, video.videoHeight);
              resolve(imageData);
            } else {
              reject("Canvas context could not be created");
            }
          } catch (e) {
            reject(e);
          }
        };

        video.addEventListener("loadeddata", () => {
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          video.currentTime = time;
        });

        canvas.addEventListener("error", (event) => {
          reject(event.type + ": " + event.message + ".");
        });

        video.addEventListener("seeked", handleVideoSeeked);

        video.addEventListener("error", (event) => {
          reject(event.type + ": " + event.message + ".");
        });

        video.crossOrigin = "Anonymous";
        video.preload = "auto";
        video.src = videoUrl;
        video.load();
      } catch (e) {
        reject(e);
      }
    });
  };

  return new Promise((resolve: (frames: ImageData[]) => void, reject: (error: unknown) => void) => {
    try {
      const video = document.createElement("video");
      video.preload = "auto";
      video.addEventListener("loadeddata", () => {
        const framePromises: Promise<number | ImageData>[] = [];
        const frames: ImageData[] = [];
        for (let time = 0; time < video.duration; time += video.duration / framesToExtract) {
          framePromises.push(getVideoFrame(videoUrl, time).then((frame) => frames.push(frame)));
        }

        Promise.all(framePromises)
          .then(() => resolve(frames))
          .catch((error) => reject(error));
      });
      video.src = videoUrl;
      video.load();
    } catch (e) {
      reject(e);
    }
  });
};

interface HTMLVideoWithCaptureStream extends HTMLVideoElement {
  captureStream(): MediaStream;
}

// WARNING: Take a look to the execution steps to better understand the execution order
export const shortenVideo = (videoUrl: string, startTimeSeconds: number, endTimeSeconds: number): Promise<Blob> => {
  return new Promise((resolve: (videoBlob: Blob) => void, reject: (error: unknown) => void) => {
    const video = document.createElement("video") as HTMLVideoWithCaptureStream;
    video.preload = "auto";
    video.crossOrigin = "anonymous";

    video.addEventListener("loadeddata", () => {
      if (isNaN(startTimeSeconds) || isNaN(endTimeSeconds) || startTimeSeconds >= endTimeSeconds) {
        reject("Invalid start or end time.");
      }

      video.addEventListener("seeked", () => {
        const chunks: BlobPart[] = [];
        const mediaRecorder = new MediaRecorder(video.captureStream());

        // STEP 4: Store the chunks of video
        mediaRecorder.ondataavailable = (event) => {
          chunks.push(event.data);
        };

        // STEP 5: Add the metadata to the video to return a seekable blob
        mediaRecorder.onstop = () => {
          const nonSeekableVideoBlob = new Blob(chunks, { type: "video/webm" });
          convertVideoBlobToSeekable(nonSeekableVideoBlob)
            .then((seekableVideoBlog) => resolve(seekableVideoBlog))
            .catch((e) => reject(e));
        };

        // STEP 3: Play the video and start recording
        // video.muted = true; // If the video is muted the recording is incorrect
        // video.playbackRate = 10; // If this is changed, the recording speed is incorrect
        video
          .play()
          .then(() => {
            mediaRecorder.start();
            setTimeout(
              () => {
                mediaRecorder?.stop();
                video.pause();
              },
              ((endTimeSeconds - startTimeSeconds) / (video.playbackRate ?? 1)) * 1000,
            );
          })
          .catch((e) => reject(e));
      });

      // STEP 2: Seek to the starting point
      video.currentTime = startTimeSeconds;
    });

    // STEP 1: Load the video metadata
    video.src = videoUrl;
    video.load();
  });
};
